/* src/components/NotFound.css */
.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa00;
    color: #343a40;
    text-align: center;
}

.not-found h1 {
    font-size: 6rem;
    margin: 0;
}

.not-found p {
    font-size: 1.5rem;
    margin: 10px 0;
}

.not-found a {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    text-decoration: none;
    color: white;
    background-color: #007bff;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.not-found a:hover {
    background-color: #0056b3;
}
