.Address-container{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 0px;
}

.Address-container .head{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    color: rgb(127, 19, 0);
}
.Address-container .head img{
    width: 30px;
    margin: 0px 10px;
}
.Address-container .Address{
    margin: 20px 0px;
    width: 93%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
}

.Address-container .Address .list{
  text-decoration: none;
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius:5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background:radial-gradient(#ae1103,#8c0301);
    border: 2px solid rgb(123, 27, 0);
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.868);
}
.Address-container .Address a{
  width: 300px;
  height:90px;
  text-decoration: none;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
}
.Address-container .Address .list:hover{
  background:radial-gradient(#d61706,#bd0502);
}

/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .Address-container .Address{
        margin: 20px 0px;
        width: 93%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
     
  }
  
  /* Normal mobile devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .Address-container .Address{
        margin: 20px 0px;
        width: 93%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
  }
  
  /* Tablets and iPads (portrait and landscape, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .Address-container .Address{
        margin: 20px 0px;
        width: 93%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
  }
  
  /* Tablets and iPads (landscape, larger than 992px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .Address-container .Address{
        margin: 20px 0px;
        width: 93%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
  }
  
  /* Laptops and PCs (larger than 1200px) */
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .Address-container .Address{
        margin: 20px 0px;
        width: 93%;
        height: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
  }
  
  /* Large screens (TVs and larger displays, 1440px and up) */
  @media (min-width: 1440px) {
    /* Add your styles here */
  }