@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
.Workshop-container{
    margin-top: 50px;
    width: 100vw;
    height: auto;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

}

.Workshop-container .workshop{
    width: 92%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    max-width: 1920px;
    
}

.Workshop-container .workshop .heading{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content:center;
    border-radius: 0px 0px 300px 300px;
    background:radial-gradient(#820000,#550202);
    color: white;
    font-weight: 100;
    font-style: normal;
    letter-spacing: 2px;
    margin-bottom: 50px;
}

.Workshop-container .workshop .course-container{
    width: 100%;
    height: auto;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-wrap: wrap;
    gap: 10px;
}

.Workshop-container .workshop .course-container .List{
    width: 200px;
    border: #550202 2px solid;
    height: 200px;
    border-radius: 0px;
    padding: 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    overflow: hidden;
}

.Workshop-container .workshop .course-container .List .img{
    width:100%;
    height:170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    overflow: hidden;
}

.Workshop-container .workshop .course-container .List .img img{
    transition: transform 0.5s ease-in-out;
    height: 130%;
    cursor: pointer;
}

.Workshop-container .workshop .course-container .List .img:hover img{
    transform:scale(1.2);
    filter: brightness(50%);
}

.Workshop-container .workshop .course-container .List .name{
   width: 100%;
   height: 45px;
   padding: 0px 10px;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content:center;
   background:radial-gradient(#820000,#550202);
   font-size: 7px;
   color: white;
   font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media (max-width:549.98px) {
  
    .Workshop-container .workshop .heading{
        width: 100%;
        height: 50px;
        font-size: 12px;
    }
    
    
    .Workshop-container .workshop .course-container .List{
        width: 170px;
        height: 170px;
        border-radius: 15px;
        margin:5px 0px;
    }
    
    .Workshop-container .workshop .course-container .List .img{
        width:100%;
        height:140px;
    }

    .Workshop-container .workshop .course-container .List .name{
       width: 100%;
       height: 30px;
       display: flex;
       align-items: center;
       justify-content:center;
       font-size: 6px;
       color: white;
    }
     
}

@media (max-width:767.98px) and (min-width:550px) {
  
    .Workshop-container .workshop .heading{
        width: 100%;
        height: 50px;
        font-size: 12px;
    }
    
    
    .Workshop-container .workshop .course-container .List{
        width: 200px;
        height: 200px;
        border-radius: 15px;
        margin: 5px 0px;
    }
    
    
    .Workshop-container .workshop .course-container .List .img{
        width:100%;
        height:170px;
    }

    .Workshop-container .workshop .course-container .List .name{
       width: 100%;
       height: 30px;
       display: flex;
       align-items: center;
       justify-content:center;
       font-size: 7px;
       color: white;
    }
     
}

@media (max-width:993.98px) and (min-width:768px) {
  
    .Workshop-container .workshop .heading{
        width: 100%;
        height: 50px;
        font-size: 12px;
    }
    
    
    .Workshop-container .workshop .course-container .List{
        width: 180px;
        height: 180px;
        border-radius: 15px;
    }
    
    .Workshop-container .workshop .course-container .List .img{
        width:100%;
        height:150px;
    }

    .Workshop-container .workshop .course-container .List .name{
       width: 100%;
       height: 30px;
       display: flex;
       align-items: center;
       justify-content:center;
       font-size: 7px;
       color: white;
    }
     
}