@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.whyuseus-container {
  width: 100vw;
  height: auto;
  margin: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.whyuseus-container .heading {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(71, 20, 5);
  margin-bottom: 40px;
  font-size: 20px;
}
.whyuseus-container .heading h1 {
  font-family: "Montserrat", sans-serif;
}

.whyuseus-container .heading::after {
  content: "";
  position: absolute;
  top: 60%;
  width: 500px;
  height: 70px;
  background: url("../assets/icons/line_1.png");
  background-position: center;
  background-size: 400px;
  background-repeat: no-repeat;
}

.whyuseus-container .whyuseus {
  width: 93%;
  height: auto;
  margin: 0px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
}

.whyuseus-container .whyuseus .section {
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: radial-gradient(#8c0808, #450303);
  border-radius: 10px;
  padding: 10px;
  cursor: url("../assets/cursor/brush.png"), pointer;
}
.whyuseus-container .whyuseus .section:hover {
  background: radial-gradient(#da1212, #6d0404);
}
.whyuseus-container .whyuseus .section .img {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: #ffffff 2px solid;
}

.whyuseus-container .whyuseus .section .img img {
  width: 60%;
}

.whyuseus-container .whyuseus .section .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
}

.whyuseus-container .whyuseus .section .contant {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  text-align: center;
}

.whyuseus-container .whyuseus .section .contant p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
  line-height: 23px;
  margin-top: 15px;
}

@media (max-width: 535px) {
  .whyuseus-container {
    width: 100vw;
    height: auto;
    margin: 15px 0px;
  }

  .whyuseus-container .heading {
    margin-bottom: 40px;
    font-size: 12px;
  }

  .whyuseus-container .heading::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 200px;
    height: 70px;
    background-size: 200px;
    background-repeat: no-repeat;
  }

  .whyuseus-container .whyuseus {
    width: 93%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .whyuseus-container .whyuseus .section {
    width: 80%;
    height: auto;
  }

  .whyuseus-container .whyuseus .section .img {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .whyuseus-container .whyuseus .section .img img {
    width: 60%;
  }

  .whyuseus-container .whyuseus .section .head {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant p {
    margin-top: 0px;
    font-weight: 200;
    line-height: 20px;
  }
}

@media (min-width: 535px) and (max-width: 767.98px) {
  .whyuseus-container {
    width: 100vw;
    height: auto;
    margin: 5px 0px;
  }

  .whyuseus-container .heading {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .whyuseus-container .heading::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 250px;
    height: 70px;
    background-size: 250px;
    background-repeat: no-repeat;
  }

  .whyuseus-container .whyuseus {
    width: 93%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .whyuseus-container .whyuseus .section {
    width: 300px;
    height: auto;
  }

  .whyuseus-container .whyuseus .section .img {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .whyuseus-container .whyuseus .section .img img {
    width: 60%;
  }

  .whyuseus-container .whyuseus .section .head {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant p {
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 768px) and (max-width: 992.98px) {
  .whyuseus-container {
    width: 100vw;
    height: auto;
    margin: 5px 0px;
  }

  .whyuseus-container .heading {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .whyuseus-container .heading::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 250px;
    height: 70px;
    background-size: 250px;
    background-repeat: no-repeat;
  }

  .whyuseus-container .whyuseus {
    width: 93%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
  }

  .whyuseus-container .whyuseus .section {
    width: 200px;
    height: auto;
  }

  .whyuseus-container .whyuseus .section .img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .whyuseus-container .whyuseus .section .img img {
    width: 50%;
  }

  .whyuseus-container .whyuseus .section .head {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant p {
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 993px) and (max-width: 1199.98px) {
  .whyuseus-container {
    width: 100vw;
    height: auto;
    margin: 5px 0px;
  }

  .whyuseus-container .heading {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .whyuseus-container .heading::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 250px;
    height: 70px;
    background-size: 250px;
    background-repeat: no-repeat;
  }

  .whyuseus-container .whyuseus {
    width: 93%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
  }

  .whyuseus-container .whyuseus .section {
    width: 250px;
    height: auto;
  }

  .whyuseus-container .whyuseus .section .img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .whyuseus-container .whyuseus .section .img img {
    width: 50%;
  }

  .whyuseus-container .whyuseus .section .head {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant p {
    font-weight: 200;
    line-height: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1449.98px) {
  .whyuseus-container {
    width: 100vw;
    height: auto;
    margin: 5px 0px;
  }

  .whyuseus-container .heading {
    margin-bottom: 40px;
    font-size: 16px;
  }

  .whyuseus-container .heading::after {
    content: "";
    position: absolute;
    top: 40%;
    width: 250px;
    height: 70px;
    background-size: 250px;
    background-repeat: no-repeat;
  }

  .whyuseus-container .whyuseus {
    width: 93%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
  }

  .whyuseus-container .whyuseus .section {
    width: 250px;
    height: auto;
  }

  .whyuseus-container .whyuseus .section .img {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .whyuseus-container .whyuseus .section .img img {
    width: 50%;
  }

  .whyuseus-container .whyuseus .section .head {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-align: center;
  }

  .whyuseus-container .whyuseus .section .contant p {
    font-weight: 200;
    line-height: 25px;
  }
}
