
/* Highlight Content---------------- */
.hightlight_container{
    position: relative;
    max-width: 1920px;
    padding: 20px;
    width: 100vw;
    height:auto;
    display: flex;
    gap:25px;
    align-items: center;
    justify-content: center;
}
.hightlight_container::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size:contain;
    opacity: 0.2;

}
.hightlight_container .hightlight_section{
    width:30vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #540000;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height: 100px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: rgb(165, 31, 31);
}
.hightlight_container .hightlight_section .content{
    width: 100%;
    padding: 10px;
    height:100px;
    font-size: 18px;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    color: rgb(182, 52, 52); 
}

/* Highlight Content End---------------- */


@media (max-width:480px) {
  
/* Highlight Content---------------- */
.hightlight_container{
    width: 100%;
    align-items: center;
    padding: 0px;
    flex-direction: column;
    gap:2px;
    justify-content: center;
  
}
.hightlight_container .hightlight_section{
    width:90%;
    height: 100%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items:center;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height:auto;
    font-size: 18px;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    color: rgb(165, 31, 31);
}
.hightlight_container .hightlight_section .content{
    width: 100%;
    padding: 0px;
    height:auto;
    font-size: 18px;
    display: flex;
    align-items:center;
}

}
/* Highlight Content End---------------- */

@media(min-width:481px){
  
    /* Highlight Content---------------- */
.hightlight_container{
    padding: 0px;
    flex-direction: column;
    gap:2px;
    margin: 20px 0px;
}
.hightlight_container .hightlight_section{
    width:90vw;
    height: 100%;
    margin: 8px 0px;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items:flex-end;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height:auto;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    text-align: center;

}
.hightlight_container .hightlight_section .content{
    width: 100%;
    padding: 0px;
    height:100px;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
}

/* Highlight Content End---------------- */
}

/* ------------------------------------------- */

@media (min-width: 769px) and (max-width: 1024px) {
       /* Highlight Content---------------- */
.hightlight_container{
    padding: 0px;
    flex-direction: column;
    gap:2px;
    margin: 20px 0px;
}
.hightlight_container .hightlight_section{
    width:50%;
    height: 100%;
    margin: 8px 0px;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items:flex-end;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height:auto;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    text-align: center;

}
.hightlight_container .hightlight_section .content{
    width: 100%;
    padding: 0px;
    height:100px;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
}
}

/* ------------------------------------------- */

@media(min-width:1025px){

/* Highlight Content---------------- */
.hightlight_container{
    padding: 20px;
    flex-direction:row;
    gap:2px;
}
.hightlight_container .hightlight_section{
    width:90vw;
    height: 100%;
    margin: 8px 0px;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items:flex-start;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height:80px;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
    justify-content: center;
    text-align: center;
}
.hightlight_container .hightlight_section .content{
    width: 70%;
    padding: 10px;
    height:100px;
    font-size: 16px;
    display: flex;
    align-items:flex-start;
}

/* Highlight Content End---------------- */

}

/* ------------------------------------------- */

@media(min-width:1201px){
     
    /* Highlight Content---------------- */
.hightlight_container{
    padding: 20px;
    flex-direction:row;
    gap:2px;
}
.hightlight_container .hightlight_section{
    width:32vw;
    height:220px;
    margin: 8px 0px;
}
.hightlight_container .hightlight_section .icon{
    width:60px;
    height:60px;
    display: flex;
    align-items:flex-end;
}
.hightlight_container .hightlight_section .head{
    width: 100%;
    height:90px;
    font-size: 20px;
    display: flex;
    text-align: center;
    align-items:center;
    justify-content: center;

}
.hightlight_container .hightlight_section .content{
    width: 90%;
    padding: 0px;
    height:100px;
    font-size: 18px;
    display: flex;
    align-items:flex-start;
    
}

/* Highlight Content End---------------- */
}
