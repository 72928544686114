/* font family link */

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


:root{
    --primary_color_dark--:rgb(97, 31, 0);
    --primary_light:rgb(233, 51, 1);
    --p--:#b3b3b3;
}

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
    color: white;
}
.scroll{
    overflow-y:hidden;
}
html{
    scroll-behavior: smooth;
}
body{

    font-family: "Roboto", sans-serif;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}/* Main page with contact details */
main{
    margin: 80px 0px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact_page{
    width: 100vw;
    height: 70px;
    display: flex;
    padding: 10px;
    margin: 10px;
    align-items: center;
    justify-content: center;
}
.contact_page h1{
    position: relative;
    color: #570000;
    font-size: 2.2em;
}
.contact_page h1:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 100%;
    background: #570000;
}
.contact-container{
    position: relative;
    width:100%;
    height:410px;
    display: flex;
    align-items:flex-end;
    justify-content: center;
}
.contact-container:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../assets//slider//group.webp');
    background-position: center;
    background-size: cover;
    opacity: 1;
    z-index: -1;
}
.contact-container .address{
    width:100%;
    max-width: 1920px;
    height: auto;
    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;
    background: #8f0000c3;
    padding: 10px 0px;
}
.contact-container .address .items{
    width:300px;
    height:150px;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
 

}
.contact-container .address .items .icon{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.contact-container .address .items .icon i{
    font-size: 2em;
    color: #ffffff;
}
.contact-container .address .items .links{
width:auto;
display: flex;
align-items:flex-start;
flex-direction: column;
}
.contact-container .address .items .links .head{
width:100%;
display: flex;
align-items:center;
flex-direction: column;
color: rgb(255, 255, 255);
font-size: 0.7em;
margin: 5px 0px;
}
.contact-container .address .items .links .head h1{
    position: relative;
}
.contact-container .address .items .links .head h1:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 100%;
    background: #c34b4b;
}
.contact-container .address .items .links .main_link{
width:100%;
display: flex;
align-items:center;
text-align: center;
flex-direction: column;
color: rgb(255, 255, 255);
font-size: 1em;
margin: 6px 0px;
}
.contact-container .address .items:nth-child(4) .links .main_link{
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.contact-container .address .items .links .main_link .social-media,
.contact-container .address .items .links .main_link .social-media a{
    width:40px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .address .items .links .main_link .social-media a img{
width: 100%;
}

.contact-container .address .items .links .main_link .s
.contact-container .address .items a{
    text-decoration: none;
    color: rgb(255, 255, 255);
}
.map{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view{
    width: 100vw;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view iframe{
    width: 100%;
    height: 100%;
}



/* Mobile device */

@media (max-width:480px) {
    /* Main page with contact details */
main{
    width: 100vw;
}

.contact_page h1{
    font-size: 1.7em;
}

.contact-container{
    width:100vw;
    height:auto;
}.contact-container .address{
    width:100%;
    max-width: 1920px;
    height: auto;
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px;
}
.contact-container .address .items{
    width:300px;
    height:auto;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
 

}
.contact-container .address .items .icon{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.contact-container .address .items .icon i{
    font-size: 2em;
}
.contact-container .address .items .links{
width:auto;
display: flex;
align-items:flex-start;
flex-direction: column;
}
.contact-container .address .items .links .head{
width:100%;
display: flex;
align-items:center;
flex-direction: column;
font-size: 0.7em;
margin: 5px 0px;
}
.contact-container .address .items .links .head h1{
    position: relative;
}
.contact-container .address .items .links .head h1:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 100%;
}
.contact-container .address .items .links .main_link{
width:100%;
display: flex;
align-items:center;
text-align: center;
flex-direction: column;
font-size: 1em;
margin: 6px 0px;
}
.contact-container .address .items:nth-child(4) .links .main_link{
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.contact-container .address .items .links .main_link .social-media,
.contact-container .address .items .links .main_link .social-media a{
    width:40px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .address .items .links .main_link .social-media a img{
width: 100%;
}

.map{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view{
    width: 100vw;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view iframe{
    width: 100%;
    height: 100%;
}

}

@media(min-width:481px) and (max-width:767.98px){
    /* Main page with contact details */
    main{
        width: 100vw;
    }
    
    .contact_page h1{
        font-size: 1.7em;
    }
    
   
.contact-container{
    width:100vw;
    height:auto;
}.contact-container .address{
    width:100%;
    max-width: 1920px;
    height: auto;
    display: flex;
    align-items:center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px;
}
.contact-container .address .items{
    width:300px;
    height:auto;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
 

}
.contact-container .address .items .icon{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.contact-container .address .items .icon i{
    font-size: 2em;
}
.contact-container .address .items .links{
width:auto;
display: flex;
align-items:flex-start;
flex-direction: column;
}
.contact-container .address .items .links .head{
width:100%;
display: flex;
align-items:center;
flex-direction: column;
font-size: 0.7em;
margin: 5px 0px;
}
.contact-container .address .items .links .head h1{
    position: relative;
}
.contact-container .address .items .links .head h1:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 100%;
}
.contact-container .address .items .links .main_link{
width:100%;
display: flex;
align-items:center;
text-align: center;
flex-direction: column;
font-size: 1em;
margin: 6px 0px;
}
.contact-container .address .items:nth-child(4) .links .main_link{
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.contact-container .address .items .links .main_link .social-media,
.contact-container .address .items .links .main_link .social-media a{
    width:40px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .address .items .links .main_link .social-media a img{
width: 100%;
}

.map{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view{
    width: 100vw;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view iframe{
    width: 100%;
    height: 100%;
}

}

@media(min-width:768px) and (max-width:1199.98px){
    /* Main page with contact details */
    main{
        width: 100vw;
    }
    
    .contact_page h1{
        font-size: 1.7em;
    }
    
   
.contact-container{
    width:100vw;
    height:auto;
}.contact-container .address{
    width:100%;
    max-width: 1920px;
    height: auto;
    display: flex;
    align-items:center;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0px;
}
.contact-container .address .items{
    width:300px;
    height:150px;
    padding: 10px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:flex-start;
 

}
.contact-container .address .items .icon{
    width:100%;
    display: flex;
    align-items: center;
    justify-content:center;
}
.contact-container .address .items .icon i{
    font-size: 1.8em;
}
.contact-container .address .items .links{
width:auto;
display: flex;
align-items:flex-start;
flex-direction: column;
}
.contact-container .address .items .links .head{
width:100%;
display: flex;
align-items:center;
flex-direction: column;
font-size: 0.6em;
margin: 5px 0px;
}
.contact-container .address .items .links .head h1{
    position: relative;
}
.contact-container .address .items .links .head h1:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 100%;
}
.contact-container .address .items .links .main_link{
width:100%;
display: flex;
align-items:center;
text-align: center;
flex-direction: column;
font-size: 1em;
margin: 6px 0px;
}
.contact-container .address .items:nth-child(4) .links .main_link{
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}
.contact-container .address .items .links .main_link .social-media,
.contact-container .address .items .links .main_link .social-media a{
    width:35px;
    height:35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .address .items .links .main_link .social-media a img{
width: 100%;
}

.map{
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view{
    width: 100vw;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map .view iframe{
    width: 100%;
    height: 100%;
}

}
