.footer {
    width: 100vw;
    background-color: #ffffff; /* Dark background for contrast */
    color: #540202; /* White text for readability */
    padding: 40px 20px;
    text-align: center;
    z-index: 999;
  }
  
  .footer-content {
    margin-bottom: 20px;
  }
  
  .footer-logo {
    width: 120px; /* Logo size */
    height: auto;
    margin-bottom: 5px;
  }
  
  .footer-text {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  
  .footer-links {
    display: flex;
    justify-content: center;
    gap: 15px; /* Space between links */
  }
  
  .footer-link {
    color: #767676; /* Light blue for links */
    text-decoration: none; /* Remove underline */
    transition: color 0.3s; /* Smooth color change */
  }
  
  .footer-link:hover {
    color: #1f1f1f; /* Change color on hover */
  }
  
  .footer-bottom {
    border-top: 1px solid #eaeaea; /* Light border for separation */
    padding-top: 5px;
  }
  
  .footer-copyright {
    font-size: 1em;
    color: #676767; /* Lighter text for copyright */
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .footer-text {
      font-size: 1em;
    }
  
    .footer-logo {
      width: 100px; /* Smaller logo on mobile */
    }
  
    .footer-link {
      font-size: 0.9em; /* Adjust link size */
    }
  }
  