*I{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
}
html,body{
    
    position: relative;
    overflow-x: hidden;
}
body::after{
content: '';
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100vh;
background:url('../assets/other_img/black_stock.png');
background-position:center;
background-size:60%;
opacity: 0.3;
z-index:-1;
}

@media (max-width:560px) {
    body::after{
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background:url('../assets/other_img/black_stock.png');
        background-position:center;
        background-size:150%;
        opacity: 0.2;
        z-index:-1;
        } 
}
/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar for horizontal scroll */
}

::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
    border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
    background: #f66b6b; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
    background: #6d0000; /* Color of the handle on hover */
}

/* For Firefox */
.scrollable {
    scrollbar-width: thin; /* Use 'thin' or 'auto' for thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* thumb color and track color */
}
