.Contact-btn-container{
    width: 100vw;
    height: 0px;
    display: flex;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 50vh;
    right: 10px;
}
.Contact-btn-container .Contact-btn .items{
    width:60px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap:10px;
}
.Contact-btn-container .Contact-btn .items .list a
.Contact-btn-container .Contact-btn .items .list{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Contact-btn-container .Contact-btn .items .list a img{
    width: 100%
}
.Contact-btn-container .Contact-btn .items .list:nth-child(2) a img{
    background-color: white;
    border-radius: 50%;
}

@media (max-width:540px) {
    .Contact-btn-container .Contact-btn .items{
        width:50px;
        gap:5px;
    }
}