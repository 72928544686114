.Admission-container {
  width: 100%;
  margin: 60px 0 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.Admission-container h1 {
  text-align: center;
  color: #6c0606;
  margin: 20px 0px;
}

.Admission-container form {
  background:radial-gradient(#d40e0e,#3e0101);
  border: #7b0303 solid 1px;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Admission-container .input-field{
  width: 100%;
  display: flex;
  gap:5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.Admission-container label {
  font-weight: bold;
  color: #ffffff;
  font-size: 18px;
}

.Admission-container .input-field input,
.Admission-container .input-field select{
  width: 100%;
  padding: 13px;
  border: 1px solid #770808;
  border-radius: 4px;
  outline:none;
  font-size:16px;
  color: #761717;
}
.Admission-container .input-field input::placeholder,
.Admission-container .input-field textarea::placeholder{
  color: #761717;
}

.Admission-container .input-field textarea{
  width: 100%;
  padding: 11px;
  height:100px;
  border: 1px solid #930606;
  border-radius: 4px;
  outline:none;
  resize: none;
  font-size:16px;
  font-family: Arial, Helvetica, sans-serif;
}

.Admission-container .form-actions{
  justify-content: center;
  width: 100%;
  display: flex;
}
.Admission-container .input-field:nth-child(13) input{
 color: white;
 border:white 1px solid;
}
.Admission-container .form-actions button {
  background-color: #28a745;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  width: 200px;
}

.Admission-container .form-actions button:hover{
  background-color: #147f2d;
}

.Admission-container .loading {
  color: blue;
  font-weight: bold;
}

.Admission-container .success {
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.Admission-container .error {
  color: rgb(255, 255, 255);
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}


/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .Admission-container {
    width: 100%;
    margin: 50px 0 20px;
    padding: 20px;
  }
  
  .Admission-container h1 {
    margin:20px 0px;
    font-size:26px;
  }
  
  .Admission-container form {
    padding: 20px;
    border-radius: 10px;
    width:100%;
    max-width:370px;
  }
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}