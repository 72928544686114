.FeedbackBox-container{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}
.FeedbackBox-container .FeedbackBox{
    width:90%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin:20px 0px;
    gap: 25px;
}
.FeedbackBox-container .FeedbackBox .heading{
   font-size: 1em;
   color: rgb(84, 2, 2);
}

.FeedbackBox-container .FeedbackBox .message{
    width: 500px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.FeedbackBox-container .FeedbackBox .message a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.FeedbackBox-container .FeedbackBox .message a input{
    width: 100%;
    height:auto;
    display: flex;
    align-items: flex-start;
    border: 2px rgb(126, 0, 0) solid;
    outline: none;
    border-radius:5px;
    padding:10px 0px 90px;
}
.FeedbackBox-container .FeedbackBox .message a input::placeholder{
    font-size: 18px;
    padding: 10px;
}

  /* Responsive styles */
  @media (max-width: 600px) {
  
    .FeedbackBox-container .FeedbackBox{
        margin:20px 0px;
        gap: 20px;
    }
    .FeedbackBox-container .FeedbackBox .heading{
       font-size: 0.8em;
    }
    
    .FeedbackBox-container .FeedbackBox .message{
        width: 330px;
        height:auto;
    }
   
    .FeedbackBox-container .FeedbackBox .message a input{
        padding:10px 0px 80px;
    }
    .FeedbackBox-container .FeedbackBox .message a input::placeholder{
        font-size: 18px;
        padding: 10px;
    }
  }
  