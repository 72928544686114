#handlePreviewImg{
    display: none;
}
.Farmer-Game-container{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background:transparent;
}
.Farmer-Game-container .Farmer-Game{
    width:90%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.Farmer-Game-container .Farmer-Game .gallery_Img{
   width: 100%;
   padding: 20px 0px;
   columns: 3;
 
}
.Farmer-Game-container .Farmer-Game .gallery_Img img{
    width:100%;
    margin:5px;
    transition: all 0.2s ease-in-out;
}
.Farmer-Game-container .Farmer-Game .gallery_Img img:hover{
    filter: brightness(30%);
    cursor: pointer;
}
/* -------------img-view------ */
.view-img-container{
    position: fixed;
    width:100vw;
    height: 100vh;
    display: none;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.view-img-container .overflow{
    position: fixed;
    width:100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.897);
}
.view-img-container .btn{
    position: absolute;
    width: 85%;
    height: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.view-img-container .btn .left,
.view-img-container .btn .right{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: #ffffff;
    opacity: 0.6;
    cursor: pointer;
}
.view-img-container .btn .left:hover,
.view-img-container .btn .right:hover{
opacity: 1;
}
.view-img-container .btn .left img,
.view-img-container .btn .right img{
    height: 60%;
}
.view-img-container .view-img{
    width: 60%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
.view-img-container .view-img .img{
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-img-container .view-img .img img {
        width:100%; /* Full width of the container */
        height: auto; /* Maintain aspect ratio */
        display: block; /* Removes extra space below the image */
        max-height:600px; 
        max-width:1200px;   
        object-fit: contain;
}

.view-img-container .view-img .img .close-img{
    position: absolute;
    background-color: #e60000;
    width: 40px;
    height: 40px;
    top: -20px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 1.3em;
    font-weight: bold;
    border-radius: 50%;
    cursor: pointer;
}
.view-img-container .view-img .img .close-img:hover{
   color: #e60000; 
   background-color: #ffffff;
}
#handlePreviewImg:checked~.view-img-container{
    display: flex;
}
/* Animations for image navigation */
.left-side-move {
    transform: translateX(200%);
    transition: transform 0.4s ease-in-out;
  }
  
  .right-side-move {
    transform: translateX(-200%);
    transition: transform 0.4s ease-in-out;
  }

  /* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .Farmer-Game-container .Farmer-Game{
        width:80%;
    }
    .Farmer-Game-container .Farmer-Game .head{
        margin: 0px 0px;
        font-size: 0.8em;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img{
       width: 100%;
       padding: 10px 0px;
       columns: 1;
     
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img{
        width:100%;
        margin:5px;
        transition: all 0.2s ease-in-out;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img:hover{
        filter: brightness(30%);
        cursor: pointer;
    }
    /* -------------img-view------ */

    .view-img-container .btn{
        position: absolute;
        width: 95%;
        z-index: 5;

    }
    .view-img-container .btn .left,
    .view-img-container .btn .right{
        width: 30px;
        height: 30px;
    }

    .view-img-container .view-img{
        width: 90%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    .view-img-container .view-img .img{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .view-img-container .view-img .img img{
        width: 100%;
    }
    .view-img-container .view-img .img .close-img{
        width: 30px;
        height: 30px;
        top: -40px;
        right: -0px;
    }

  }
  
  /* Normal mobile devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
   
    .Farmer-Game-container .Farmer-Game{
        width:80%;
    }
    .Farmer-Game-container .Farmer-Game .head{
        margin: 0px 0px;
        font-size: 0.8em;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img{
       width: 100%;
       padding: 10px 0px;
       columns: 2;
     
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img{
        width:100%;
        margin:5px;
        transition: all 0.2s ease-in-out;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img:hover{
        filter: brightness(30%);
        cursor: pointer;
    }
    /* -------------img-view------ */

    .view-img-container .btn{
        position: absolute;
        width: 95%;
        z-index: 5;

    }
    .view-img-container .btn .left,
    .view-img-container .btn .right{
        width: 30px;
        height: 30px;
    }

    .view-img-container .view-img{
        width: 90%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    .view-img-container .view-img .img{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .view-img-container .view-img .img img{
        width: 100%;
    }
    .view-img-container .view-img .img .close-img{
        width: 30px;
        height: 30px;
        top: -40px;
        right: -0px;
    }

  }
  
  /* Tablets and iPads (portrait and landscape, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .Farmer-Game-container .Farmer-Game{
        width:80%;
    }
    .Farmer-Game-container .Farmer-Game .head{
        margin: 0px 0px;
        font-size: 0.8em;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img{
       width: 100%;
       padding: 10px 0px;
       columns: 2;
     
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img{
        width:100%;
        margin:5px;
        transition: all 0.2s ease-in-out;
    }
    .Farmer-Game-container .Farmer-Game .gallery_Img img:hover{
        filter: brightness(30%);
        cursor: pointer;
    }
    /* -------------img-view------ */

    .view-img-container .btn{
        position: absolute;
        width: 95%;
        z-index: 5;

    }
    .view-img-container .btn .left,
    .view-img-container .btn .right{
        width: 30px;
        height: 30px;
    }

    .view-img-container .view-img{
        width: 90%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }
    .view-img-container .view-img .img{
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .view-img-container .view-img .img img{
        width: 100%;
    }
    .view-img-container .view-img .img .close-img{
        width: 30px;
        height: 30px;
        top: -40px;
        right: -0px;
    }
  }
  
  /* Tablets and iPads (landscape, larger than 992px) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    /* Add your styles here */
  }
  
  /* Laptops and PCs (larger than 1200px) */
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    /* Add your styles here */
  }
  
  /* Large screens (TVs and larger displays, 1440px and up) */
  @media (min-width: 1440px) {
    /* Add your styles here */
  }