/* import font */
@font-face {
    font-family:"PersonalUseOnly";
    src: url('../assets//fonts/Silent\ Asia\ -\ Personal\ Use.otf') format("truetype");
}

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.children-art-course-container{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:61px;
    background: #fff;
}
.children-art-course-container .children-art-course{
    width:100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.children-art-course-container .children-art-course .course_Img{
    width:100%;
    height:230px;
    background: #6e110e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.children-art-course-container .children-art-course .course_Img h1{
    color: white;
    font-family: 'PersonalUseOnly';
    font-size: 60px;
    text-align: center;
    font-weight:100;
}

.children-art-course-container .children-art-course .course_Img::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/course_Info_Img/child_drawing.jpg');
    background-size:700px;
    background-position:left;
    opacity: 0.2;
    z-index: -1;
}
.children-art-course-container .children-art-course .course_Img img{
    width: 100%;
    
}
.children-art-course-container .children-art-course h3{
    color: rgb(255, 111, 0);
    font-size:28px;
    font-weight: bold;
    text-transform: capitalize;
}
.children-art-course-container .children-art-course .content{
    width:60%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.children-art-course-container .children-art-course .content p{
    font-size:20px;
    line-height: 28px;
    color: #651313;
    text-align: justify;
    margin: 10px 0px;
    font-family: "Roboto", serif;
    font-weight:500;
}
.children-art-course-container .children-art-course .content p::first-letter{
    margin-left:50px;
}

.children-art-course-container .children-art-course .content h1{
    font-size: 28px;
    margin: 0px 0px 0px 0px;
    color:#6c1810;
    font-family: "Roboto", serif;

    
}

.children-art-course-container .children-art-course .content ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:8px;
    margin: 10px 0px;
}

.children-art-course-container .children-art-course .content ul li{
    font-size: 18px;
    color: #651313;
    font-family: "Inter", serif;
    font-weight:600;
    line-height: 28px;
}

.children-art-course-container .children-art-course .content ul li strong{
    font-size: 20px;
    color: #c66b10;
    font-family: "Inter", serif;
}



/* Common styles for all devices */

/* Small mobile devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

    .children-art-course-container .children-art-course{
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }
    .children-art-course-container .children-art-course .course_Img{
        width:100%;
        height:230px;
        background: #6e110e;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }
    
    .children-art-course-container .children-art-course .course_Img h1{
        font-size: 34px;
        font-weight:100;
    }
    
    .children-art-course-container .children-art-course .course_Img::after{
        content: '';
        background-size:cover;
    }
    .children-art-course-container .children-art-course h3{
        font-size:22px;
        margin:10px 0px;
    }
    .children-art-course-container .children-art-course .content{
        width:90%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .children-art-course-container .children-art-course .content p{
        font-size:18px;
        line-height: 28px;
        margin: 0px 0px;
    }
    .children-art-course-container .children-art-course .content p::first-letter{
        margin-left:30px;
    }
    
    .children-art-course-container .children-art-course .content h1{
        font-size: 24px;
        margin: 10px 0px 0px 0px;
        line-height:30px;        
    }
    
    .children-art-course-container .children-art-course .content ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        gap:8px;
        margin: 10px 0px;
    }
    
    .children-art-course-container .children-art-course .content ul li{
        font-size: 16px;
        font-weight:600;
        line-height: 28px;
    }
    
    .children-art-course-container .children-art-course .content ul li strong{
        font-size:18px;
    }
        
}

/* Normal mobile devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

    .children-art-course-container .children-art-course{
        width:100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }
    .children-art-course-container .children-art-course .course_Img{
        width:100%;
        height:230px;
        background: #6e110e;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }
    
    .children-art-course-container .children-art-course .course_Img h1{
        font-size: 34px;
        font-weight:100;
    }
    
    .children-art-course-container .children-art-course .course_Img::after{
        content: '';
        background-size:cover;
    }
    .children-art-course-container .children-art-course h3{
        font-size:22px;
        margin:10px 0px;
    }
    .children-art-course-container .children-art-course .content{
        width:90%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }
    .children-art-course-container .children-art-course .content p{
        font-size:18px;
        line-height: 28px;
        margin: 0px 0px;
    }
    .children-art-course-container .children-art-course .content p::first-letter{
        margin-left:30px;
    }
    
    .children-art-course-container .children-art-course .content h1{
        font-size: 24px;
        margin: 10px 0px 0px 0px;
        line-height:30px;        
    }
    
    .children-art-course-container .children-art-course .content ul{
        list-style: none;
        display: flex;
        flex-direction: column;
        gap:8px;
        margin: 10px 0px;
    }
    
    .children-art-course-container .children-art-course .content ul li{
        font-size: 16px;
        font-weight:600;
        line-height: 28px;
    }
    
    .children-art-course-container .children-art-course .content ul li strong{
        font-size:18px;
    }
}

/* Tablets and iPads (portrait and landscape, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .children-art-course-container .children-art-course h3{
        font-size:25px;
        margin:10px 0px;
    }
}

/* Tablets and iPads (landscape, larger than 992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {

}

/* Laptops and PCs (larger than 1200px) */
@media (min-width: 1200px) and (max-width: 1439.98px) {

}

/* Large screens (TVs and larger displays, 1440px and up) */
@media (min-width: 1440px) {
  /* Add your styles here */
}