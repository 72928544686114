.TeachingMethods-container{
    width: 100vw;
    height: auto;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TeachingMethods-container .head{
    position: relative;
    width: 100%;
    height: auto;
    margin: 20px 0px;
    display: flex;
    align-items: center;
   justify-content: center;
   font-size: 18px;
   color: rgb(111, 23, 8);
}
.TeachingMethods-container .head::after{
    content: '';
    position: absolute;
    top: 60%;
    width: 500px;
    height: 70px;
    background:url('../assets/icons/line_1.png');
    background-position: center;
    background-size:400px;
    background-repeat: no-repeat;
}

.TeachingMethods-container .TeachingMethods{
    width: 100%;
    height: auto;
    margin: 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.TeachingMethods-container .TeachingMethods .list{
    padding: 10px;
    width:300px;
    height:200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: radial-gradient(#9c0404,#500303);
    border-radius: 20px;
}
.TeachingMethods-container .TeachingMethods .list:hover{
    background: radial-gradient(#cd0606,#a40303);
    cursor:url("../assets//cursor/magic-tick.png"),auto;
}
.TeachingMethods-container .TeachingMethods .list:nth-child(2){
    height: 250px;
    justify-content: center;
}

.TeachingMethods-container .TeachingMethods .list .img{
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 4px white solid;
}

.TeachingMethods-container .TeachingMethods .list .img img{
    width: 60%;

}

.TeachingMethods-container .TeachingMethods .list .class{
    width: 90%;
    text-align: center;
    color: white;
    font-size: 10px;
}

@media (max-width:524.98px) {
    .TeachingMethods-container .head{
        position: relative;
        width: 100%;
        height: auto;
        margin: 20px 0px;
        display: flex;
        align-items: center;
       justify-content: center;
       font-size: 14px;
    }
    .TeachingMethods-container .head::after{
        content: '';
        position: absolute;
        top: 40%;
        width: 230px;
        height: 70px;
        background-size:230px;
        background-repeat: no-repeat;
    }
    .TeachingMethods-container .TeachingMethods{
        width: 100%;
        height: auto;
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
    .TeachingMethods-container .TeachingMethods .list{
        padding: 10px;
        width:250px;
        height:210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border-radius: 20px;
    }

    .TeachingMethods-container .TeachingMethods .list:nth-child(2){
        height: 210px;
        justify-content: center;
    }
    
    .TeachingMethods-container .TeachingMethods .list .img{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    
    .TeachingMethods-container .TeachingMethods .list .img img{
        width: 60%;
    
    }
    
    .TeachingMethods-container .TeachingMethods .list .class{
        width: 90%;
        text-align: center;
        font-size: 10px;
    }
}

@media (max-width:767.98px) and (min-width:525px) {
    .TeachingMethods-container .head{
        position: relative;
        width: 100%;
        height: auto;
        margin: 20px 0px;
        display: flex;
        align-items: center;
       justify-content: center;
       font-size: 18px;
    }
    .TeachingMethods-container .head::after{
        content: '';
        position: absolute;
        top: 60%;
        width: 330px;
        height: 70px;
        background-size:330px;
        background-repeat: no-repeat;
    }
    .TeachingMethods-container .TeachingMethods{
        width: 100%;
        height: auto;
        margin: 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
    .TeachingMethods-container .TeachingMethods .list{
        padding: 10px;
        width:250px;
        height:210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border-radius: 20px;
    }

    .TeachingMethods-container .TeachingMethods .list:nth-child(2){
        height: 210px;
        justify-content: center;
    }
    
    .TeachingMethods-container .TeachingMethods .list .img{
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    
    .TeachingMethods-container .TeachingMethods .list .img img{
        width: 60%;
    
    }
    
    .TeachingMethods-container .TeachingMethods .list .class{
        width: 90%;
        text-align: center;
        font-size: 10px;
    }
}