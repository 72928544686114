    .trustedourclient-container{
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0px;
  
}
.trustedourclient-container .trustedour{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content:center;
    gap: 50px;
}
.trustedourclient-container .trustedour .left{
    width:auto;
    display: flex;
    align-items: center;
    justify-content:center;
}
.trustedourclient-container .trustedour .left h1{
    width: 70%;
    font-size: 48px;
    color:#DB7D40;
}
.trustedourclient-container .trustedour .right{
    width:auto;
    display: flex;
    align-items: flex-start;
    justify-content:start;
}

.trustedourclient-container .trustedour .right .feedback-list{
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content:start;
}

.trustedourclient-container .trustedour .right .list{
    width:700px;
    padding: 20px;
    height: auto;
    min-height: 180px;
    display: none;
    align-items: flex-start;
    flex-direction: column;
    justify-content:flex-start;
    box-shadow: 1px 1px 10px rgba(63, 63, 63, 0.144);
    border: 1px rgb(224, 224, 224) solid;
    background: #ffffff;
    border-radius: 20px;
    
}
.trustedourclient-container .trustedour .right .active{
    opacity: 1;
    display: flex;
    animation:feedback-message 1s ease-in-out;
}
@keyframes feedback-message {
    0%{
        opacity: 0;
        transform:translateY(50px)
    }
    100%{
        opacity: 1;
        transform:translateY(0px)
    }
}
.trustedourclient-container .trustedour .right .list .profile-name{
   width: 100%;
   height:auto;
   margin: 0px 0px 10px 0px;
   display: flex;
   align-items:flex-start;
   justify-content: space-between;
}
.trustedourclient-container .trustedour .right .list .profile-name .user-name{
   width: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 15px;
}

.trustedourclient-container .trustedour .right .list .profile-name .user-name .left{
   width: auto;
   flex-direction: column;
   display: flex;
   align-items: center;
   justify-content: center;
}
.trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
    width:50px;
    height:50px;
    overflow: hidden;
    border-radius: 50%;
    display:flex;
    align-items: center;
    justify-content: center;
    background: #00000065;
}
.trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img img{
    width: 100%;
}

.trustedourclient-container .trustedour .right .list .profile-name .user-name .right{
    width: auto;
    height: auto;
    flex-direction: column;
    display: flex;
    align-items:flex-start;
    justify-content:center;
 }
 
 .trustedourclient-container .trustedour .right .list .profile-name .rate{
    width: auto;
    height: auto;
    gap: 5px;
    display:flex;
    align-items: center;
    justify-content: center;
 }

 .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
    width: 20px;
 }
 .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
    font-size:20px;
    font-weight: 600;
 }

 .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
    font-size:16px;
    color: #4D423D;
 }
 .trustedourclient-container .trustedour .right .list .message{
   width:100%;
   display: flex;
   align-items: center;
   justify-content: center;
 }
 .trustedourclient-container .trustedour .right .list a{
    color: black;
 }
 .trustedourclient-container .trustedour .right .list .message p{
   width:95%;
   display: flex;
   align-items: center;
   justify-content: flex-start;
 }

 @media (max-width:575.98px) {

    .trustedourclient-container .trustedour{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
 
    .trustedourclient-container .trustedour .left h1{
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    .trustedourclient-container .trustedour .right{
        width:100%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
    }
    
    
    .trustedourclient-container .trustedour .right .list{
        width:280px;
        padding: 10px;
        height: auto;
        min-height: 130px; 
    }

    .trustedourclient-container .trustedour .right .list .profile-name{
       margin: 0px 0px 5px 0px;
    }
    .trustedourclient-container .trustedour .right .list .profile-name .user-name{
       gap: 10px;
    }

    .trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
        width:35px;
        height:35px;
    }


     .trustedourclient-container .trustedour .right .list .profile-name .rate{
        width: auto;
        height: auto;
        gap: 1px;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
        width: 12px;
     }
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
        font-size:12px;
        font-weight: 600;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
        font-size:10px;
     }
     .trustedourclient-container .trustedour .right .list .message{
       width:100%;
       font-size: 12px;
       line-height: 15px;
     }
 }


 @media (min-width:576px) and (max-width:767.98px) {

    .trustedourclient-container .trustedour{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
    }
 
    .trustedourclient-container .trustedour .left h1{
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    .trustedourclient-container .trustedour .right{
        width:100%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
    }
    
    
    .trustedourclient-container .trustedour .right .list{
        width:310px;
        padding: 10px;
        height: auto;
        min-height: 130px; 
    }

    .trustedourclient-container .trustedour .right .list .profile-name{
       margin: 0px 0px 5px 0px;
    }
    .trustedourclient-container .trustedour .right .list .profile-name .user-name{
       gap: 10px;
    }

    .trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
        width:35px;
        height:35px;
    }


     .trustedourclient-container .trustedour .right .list .profile-name .rate{
        width: auto;
        height: auto;
        gap: 1px;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
        width: 12px;
     }
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
        font-size:12px;
        font-weight: 600;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
        font-size:10px;
     }
     .trustedourclient-container .trustedour .right .list .message{
       width:100%;
       font-size: 10px;
     }
 }


 @media (min-width:768px) and (max-width:992.98px) {

    .trustedourclient-container .trustedour{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
 
    .trustedourclient-container .trustedour .left h1{
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    .trustedourclient-container .trustedour .right{
        width:70%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
    }
    
    
    .trustedourclient-container .trustedour .right .list{
        width:310px;
        padding: 10px;
        height: auto;
        min-height: 130px; 
    }

    .trustedourclient-container .trustedour .right .list .profile-name{
       margin: 0px 0px 5px 0px;
    }
    .trustedourclient-container .trustedour .right .list .profile-name .user-name{
       gap: 10px;
    }

    .trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
        width:35px;
        height:35px;
    }


     .trustedourclient-container .trustedour .right .list .profile-name .rate{
        width: auto;
        height: auto;
        gap: 1px;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
        width: 12px;
     }
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
        font-size:12px;
        font-weight: 600;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
        font-size:10px;
     }
     .trustedourclient-container .trustedour .right .list .message{
       width:100%;
       font-size: 10px;
     }
 }


 @media (min-width:993px) and (max-width:1199.98px) {

    .trustedourclient-container .trustedour{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
 
    .trustedourclient-container .trustedour .left h1{
        width: 100%;
        text-align: center;
        font-size: 30px;
    }
    .trustedourclient-container .trustedour .right{
        width:80%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
    }
    
    
    .trustedourclient-container .trustedour .right .list{
        width:370px;
        padding: 10px;
        height: auto;
        min-height: 130px; 
    }

    .trustedourclient-container .trustedour .right .list .profile-name{
       margin: 0px 0px 5px 0px;
    }
    .trustedourclient-container .trustedour .right .list .profile-name .user-name{
       gap: 10px;
    }

    .trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
        width:40px;
        height:40px;
    }


     .trustedourclient-container .trustedour .right .list .profile-name .rate{
        width: auto;
        height: auto;
        gap: 1px;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
        width: 14px;
     }
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
        font-size:14px;
        font-weight: 600;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
        font-size:12px;
     }
     .trustedourclient-container .trustedour .right .list .message{
       width:100%;
       font-size: 12px;
     }
 }

 @media (min-width:1200px) and (max-width:1439.98px) {

    .trustedourclient-container .trustedour{
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
 
    .trustedourclient-container .trustedour .left h1{
        width: 100%;
        text-align: center;
        font-size: 40px;
    }
    .trustedourclient-container .trustedour .right{
        width:80%;
        display: flex;
        align-items: flex-start;
        justify-content:center;
    }
    
    
    .trustedourclient-container .trustedour .right .list{
        width:470px;
        padding: 10px;
        height: auto;
        min-height: 160px; 
    }

    .trustedourclient-container .trustedour .right .list .profile-name{
       margin: 0px 0px 5px 0px;
    }
    .trustedourclient-container .trustedour .right .list .profile-name .user-name{
       gap: 10px;
    }

    .trustedourclient-container .trustedour .right .list .profile-name .user-name .left .img{
        width:40px;
        height:40px;
    }


     .trustedourclient-container .trustedour .right .list .profile-name .rate{
        width: auto;
        height: auto;
        gap: 1px;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name  .rate img{
        width: 18px;
     }
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .user_name{
        font-size:14px;
        font-weight: 600;
     }
    
     .trustedourclient-container .trustedour .right .list .profile-name .user-name .right .occupation{
        font-size:12px;
     }
     .trustedourclient-container .trustedour .right .list .message{
       width:100%;
       font-size: 14px;
     }
 }